
    import React, { Fragment } from 'react';
    
    
    const Markdown = () => (<Fragment><ul>
<li>Valentin Laurent</li>
<li>François Philippe</li>
<li>François Ubald Brien</li>
<li>Pierre-Antoine Chesnel</li>
<li>Michal Seta</li>
<li>Pascale Stark</li>
<li>Francis Lecavalier</li>
<li>Rostom Mesli</li>
<li>Guillaume Riou</li>
<li>Olivier Gauthier</li>
<li>Nicolas Bouillot</li>
<li>Rochana Fardon</li>
<li>Matthew Hills</li>
<li>Serigne Saliou Dia</li>
<li>Jérémie Soria</li>
<li>Emmanuel Durand</li>
<li>Arnaud Grosjean</li>
<li>Alexandre Gauthier</li>
</ul>
</Fragment>);
    export default Markdown;
  